import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const Services = (props) => (
    <Layout>
        <Helmet>
            <title>2024 CHCSD Services</title>
            <meta name="description" content="2024 CHCSD Services Page" />
        </Helmet>

        <div id="main" className="alt">

            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>2024 Services</h1>
                    </header>

                    <div className="grid-wrapper">
                        <div className="col-6">
                            <h3>Complimentary Registration</h3>
                            <p>Current legislators, non-homeschooling pastors and non-homeschooling grandparents are invited to attend the conference as our guests. CHCSD does not endorse any political party, legislator or denomination, but instead uses their attendance at the conference to acquaint them with home education as a successful, proven educational choice. Please feel free to take a moment and introduce yourselves to them. Legislators, please bring credentials to receive your free pass.</p>
                        </div>
                        <div className="col-6">
                            <h3>Food</h3>
                            <p>No outside food is allowed in the Convention Center. An expanded menu of lunch and snack choices will be available from concessions in Exhibit Hall 2, behind the vendor booths. Food purchased at the Convention Center concessions helps to defray the cost of the conference.</p>
                        </div>
                        <div className="col-6">
                            <h3>Mother's Day Basket Drawing</h3>
                            <p>One special mother will go home with this year's Mother's Day Basket. Multiple entries available per family: TEACHSD member (1), completed vendor punch cards (2), completed conference survey (1). The drawing will be held at 4:25 p.m. Saturday in Exhibit Hall 1 before the start of the last keynote presentation. <i>Must be present to win</i>.</p>
                        </div>
                        <div className="col-6">
                            <h3>Speaker Conference Recordings</h3>
                            <p>Had to flip a coin to decide which session to attend? Wondered what you might have missed? Wonder no more! Once again, audio of all keynote and workshop speaker presentations, unless otherwise noted, will be available in mp3 format. Recordings do not include vendor workshops or special sessions. Conference attendance is required, and the service cost is $15. Recordings are available for purchase at the registration table or on the Eventbrite app. Those who purchase recordings will receive a download link, at the email provided at registration, within 2 weeks of the conference conclusion.</p>
                        </div>
                        <div className="col-6">
                            <h3>Unstaffed Kid's Areas</h3>
                            <p>Need a place to let the little ones, or those watching them, unwind? There is an <strong>Unstaffed Nursery</strong> in Room 5, west of the main entrance to the Convention Center. There will also be <strong>Unstaffed Respite Tables</strong> in the main hallway between the CHCSD Information Tables and the doors to Exhibit Hall 1 doors. The <strong>Unstaffed Nursery</strong> is intended for nursing mothers and smaller children while the <strong>Unstaffed Respite Tables</strong> are for older children who'd like to hang out and play games or just rest a bit. Please note that these areas are unstaffed and adults are still responsible for their children while they're in these areas.</p>
                        </div>
                        <div className="col-6">
                            <h3>Vendor Hall</h3>
                            <p>The Vendor Hall is where you can browse and purchase curricula, books and supplies, as well as learn about new educational resources and methods, consult with experts and interact with the conference speakers.</p>
                        </div>
                        <div className="col-6">
                            <h3>Vendor Prize Drawings</h3>
                            <p>Enter to win amazing prizes donated by participating vendors! To enter the drawing, complete your vendor stamp cards by receiving a stamp while visiting participating vendors. Look for the yellow stamp card flag to identify each participating vendor booth. Vendors may require an adult to visit their booth in order to receive a stamp. Prizes must be picked up from the vendor during Vendor Hall hours. Enter early for more opportunities to win hourly vendor prizes.</p>
                        </div>
                    </div>

                </div>
            </section>

        </div>

    </Layout>
)

export default Services
